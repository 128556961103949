import { buildSchema } from "@camberi/firecms";
import { ConciergeFeedItem } from "../types.d";

export default buildSchema<ConciergeFeedItem>({
    name: "ConciergeFeedItem",
    properties: {
        market: {
            title: "Market",
            validation: { required: true },
            dataType: "reference",
            path: "cms-markets",
        },
        actionText: {
            title: "Action Text",
            validation: { required: true, max: 250 },
            dataType: "string",
        },
        itemName: {
            title: "Item Name",
            validation: { required: true, max: 250 },
            dataType: "string",
        },
        imageUrl: {
            title: "Image Url",
            validation: { required: true },
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "public",
                    acceptedFiles: ["image/*"],
                    storeUrl: true,
                },
            },
        },
        videoUrl: {
            title: "Video Url",
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "video",
                    storagePath: "public",
                    acceptedFiles: ["video/*"],
                    storeUrl: true,
                },
            },
        },
        itemType: {
            title: "Item Type",
            validation: { required: true },
            dataType: "string",
            config: {
                enumValues: {
                    service: { label: "Service", color: "blueDarker" },
                    promoService: { label: "Promo Service", color: "blueDark" },
                    familyEvent: { label: "Family Event", color: "greenDark" },
                    gameEvent: { label: "Game Event", color: "redDarker" },
                    otherEvent: { label: "Other Event", color: "yellowDark" },
                },
            },
        },
        avatarImageUrl: {
            title: "Avatar Image",
            validation: { required: false },
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "public",
                    acceptedFiles: ["image/*"],
                    storeUrl: true,
                },
            },
        },
        itemId: {
            title: "Item Id",
            validation: { required: false },
            dataType: "string",
        },
    },
});
