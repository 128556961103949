import { buildSchema, buildProperties, buildProperty } from "@camberi/firecms";
import { Ticket } from "../types.d";

export default buildSchema<Ticket>({
    name: "Tickets",
    properties: {
        shortName: {
            title: "Short Name",
            dataType: "string",
            validation: { required: true, max: 100 },
        },
        shortDescription: {
            title: "Short Description",
            dataType: "string",
            validation: { required: true, max: 150 },
        },
        longName: {
            title: "Long Name",
            dataType: "string",
            validation: { required: true, max: 100 },
        },
        longDescriptionItems: {
            title: "Long Description Items",
            dataType: "array",
            validation: { required: true, min: 1 },
            oneOf: {
                typeField: "type",
                valueField: "value",
                properties: {
                    header: {
                        title: "Header",
                        dataType: "string",
                        validation: { required: true, max: 100 },
                    },
                    body: {
                        title: "Body",
                        dataType: "string",
                        validation: { required: true },
                    },
                    callButton: {
                        title: "Call Button",
                        dataType: "map",
                        properties: {
                            text: {
                                title: "Text",
                                dataType: "string",
                                validation: { required: true, max: 100 },
                            },
                            tel: {
                                title: "Tel",
                                dataType: "string",
                                validation: { required: true, max: 15 },
                            },
                        },
                    },
                },
            },
        },
        image: {
            title: "Promo Image",
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "public",
                    acceptedFiles: ["image/*"],
                    storeUrl: true,
                },
            },
        },
        teamIds: {
            title: "Teams",
            dataType: "array",
            of: {
                dataType: "reference",
                path: "cms-teams",
            },
        },
    },
});
