import { buildSchema, ChipColor } from "@camberi/firecms";
import { Event } from "../types.d";

export default buildSchema<Event>({
    name: "Event",
    defaultValues: {
        address: {
            geoLocation: {
                lat: 0,
                lon: 0,
            },
        },
    },
    properties: {
        title: {
            title: "Title",
            validation: { required: true, max: 250 },
            dataType: "string",
        },
        date: {
            title: "Date",
            validation: { required: true },
            dataType: "timestamp",
        },
        type: {
            title: "Event Category",
            validation: { required: true },
            dataType: "string",
            config: {
                enumValues: {
                    family: { label: "Family", color: "greenDark" },
                    game: { label: "Game", color: "redDarker" },
                    other: { label: "Other", color: "yellowDark" },
                },
            },
        },
        descriptions: {
            title: "Description",
            validation: { required: true },
            dataType: "array",
            of: {
                dataType: "map",
                properties: {
                    title: {
                        title: "Title",
                        dataType: "string",
                        validation: { max: 250 },
                    },
                    paragraphs: {
                        title: "Paragraphs",
                        dataType: "array",
                        of: {
                            dataType: "string",
                            validation: { max: 250 },
                        },
                    },
                },
            },
        },
        imageUrl: {
            title: "Image",
            validation: { required: false },
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "public",
                    acceptedFiles: ["image/*"],
                    storeUrl: true,
                },
            },
            dataType: "string",
        },
        address: {
            title: "Address",
            validation: { required: true },
            dataType: "map",
            properties: {
                streetAddress: {
                    title: "Street Address",
                    dataType: "string",
                },
                locality: {
                    title: "City",
                    dataType: "string",
                },
                region: {
                    title: "State",
                    dataType: "string",
                },
                zipCode: {
                    title: "Zip Code",
                    dataType: "string",
                },
                geoLocation: {
                    title: "GPS Coordinates",
                    dataType: "map",
                    validation: { required: true },
                    properties: {
                        googleMapsUrl: {
                            title: "Google Maps Url",
                            dataType: "string",
                        },
                        lat: {
                            title: "Latitude",
                            dataType: "number",
                            validation: { required: true },
                        },
                        lon: {
                            title: "Longitude",
                            dataType: "number",
                            validation: { required: true },
                        },
                    },
                },
            },
        },
        websiteUrl: {
            title: "Website",
            validation: { required: false },
            dataType: "string",
        },
        parkingNotes: {
            title: "Additional Notes",
            validation: { required: false },
            dataType: "array",
            of: {
                dataType: "string",
                validation: { max: 250 },
            },
        },
        commonQuestions: {
            title: "Common Questions",
            validation: { required: false },
            dataType: "array",
            of: {
                dataType: "map",
                properties: {
                    question: {
                        title: "Question",
                        dataType: "string",
                        validation: { max: 250 },
                    },
                    answer: {
                        title: "Answer",
                        dataType: "string",
                        validation: { max: 250 },
                    },
                },
            },
        },
        teamIds: {
            title: "Teams",
            dataType: "array",
            of: {
                dataType: "reference",
                path: "cms-teams",
            },
        },
    },
});
