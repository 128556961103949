import React, { useState } from "react";
import { FieldDescription, FieldProps } from "@camberi/firecms";
import { HexColorPicker, HexColorInput } from "react-colorful";

interface ColorPickerComponentTextFieldProps {
    color: string;
}

export default function ColorPickerComponent({
    property,
    value,
    setValue,
    customProps,
    touched,
    error,
    isSubmitting,
    context,
    ...props
}: FieldProps<string, ColorPickerComponentTextFieldProps>) {
    const [color, setColor] = useState(value ?? customProps.color);

    return (
        <>
            <div className="App">
                {property.title}
                <HexColorPicker
                    color={color}
                    onChange={(evt: any) => {
                        setColor(evt);
                        setValue(evt);
                    }}
                />

                <HexColorInput
                    color={color}
                    onChange={(evt: any) => {
                        setValue(evt);
                        setColor(evt);
                    }}
                />
            </div>
            <FieldDescription property={property} />
        </>
    );
}
