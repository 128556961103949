import { buildSchema } from "@camberi/firecms";
import { Market } from "../types.d";

export default buildSchema<Market>({
    name: "Market",
    properties: {
        name: {
            title: "Name",
            validation: { required: true, max: 250 },
            dataType: "string",
        },
        location: {
            title: "Location",
            validation: { required: true },
            dataType: "map",
            properties: {
                googleMapsUrl: {
                    title: "Google Maps Url",
                    dataType: "string",
                },
                lat: {
                    title: "Latitude",
                    dataType: "number",
                    validation: { required: true, min: -90, max: 90 },
                },
                lon: {
                    title: "Longitude",
                    dataType: "number",
                    validation: { required: true, min: -180, max: 180 },
                },
            },
        },
    },
});
