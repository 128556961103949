import React, { ReactElement } from "react";
import { PreviewComponentProps } from "@camberi/firecms";

import TextField from "@mui/material/TextField";

export default function ColorPickerPreview({
    value,
    property,
    size,
}: PreviewComponentProps<string>): ReactElement {
    return <TextField sx={{ backgroundColor: value }} />;
}
