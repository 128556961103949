import React from "react";
import "typeface-rubik";
import "typeface-space-mono";
import { User as FirebaseUser } from "firebase/auth";

import {
    Authenticator,
    buildCollection,
    FirebaseCMSApp,
    NavigationBuilder,
    NavigationBuilderProps,
    CMSView,
    Entity,
    EntityCollection,
    WhereFilterOp,
    EntityReference,
    FilterValues,
    buildSchema,
} from "@camberi/firecms";

import {
    recommendationCallbacks,
    serviceCallbacks,
    arenaCallbacks,
    eventCallbacks,
} from "./callbacks";

import { CommentModerationView } from "./customComponents/CommentModeration";
import { ServiceView } from "./customComponents/Services";

import marketSchema from "./schemas/markets";
import conciergeFeedItemSchema from "./schemas/conciergeFeedItem";
import promoServiceSchema from "./schemas/promoService";
import { serviceSchema, checkinSchema } from "./schemas/service";
import recommendationSchema from "./schemas/recommendation";
import eventSchema from "./schemas/event";
import teamSchema from "./schemas/team";
import teamArenaSchema from "./schemas/arenas";
import teamTicketSchema from "./schemas/tickets";
import teamContactSchema from "./schemas/contacts";
import userProfileSchema from "./schemas/userProfileSchema";
import teamInvites from "./schemas/teamInvites";
import { Team } from "./types.d";
import { DocumentReference } from "@firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export default function App() {
    const customViews: CMSView[] = [
        {
            path: ["comments", "comments/:id"],
            name: "Comment Moderation (admin)",
            description: "Comment moderation tools.",
            view: <CommentModerationView />,
        },
        {
            path: ["services", "services/:id"],
            name: "Services (admin)",
            description: "Create and manage services for each market.",
            view: <ServiceView />,
        },
    ];

    const navigation: NavigationBuilder = async ({
        authController,
    }: NavigationBuilderProps) => {
        const userId = authController.user.uid;
        const isAdmin = authController.extra.roles.includes("admin");

        // if we're an admin, show all the teams
        const teamFilter = isAdmin
            ? {}
            : {
                  teamManagers: [
                      "array-contains" as WhereFilterOp,
                      new EntityReference(userId ?? "", "/users/"),
                  ],
              };

        // returns true if one of the team IDs is in the user's managed teams
        // list, they're an admin, or there are no teamIDs
        function canManageTeamEntity(entity: Entity<any> | null) {
            const entityTeamIds =
                entity?.values?.teamIds?.map(
                    (teamEntity: DocumentReference) => {
                        return teamEntity.id;
                    }
                ) || [];
            const canManage = !!authController.extra.managedTeams.filter(
                (managedTeam: { id: string }) => {
                    // return false;
                    return entityTeamIds.includes(managedTeam.id);
                }
            ).length;
            return canManage || isAdmin || entity?.values?.teamIds?.length == 0;
        }

        const collections: EntityCollection[] = [
            buildCollection({
                path: "cms-team-events",
                schema: eventSchema,
                callbacks: eventCallbacks,
                name: "Events",
                description: "Manage League Life events.",
                permissions: ({ entity }) => {
                    return {
                        edit: canManageTeamEntity(entity),
                        create: true,
                        delete: canManageTeamEntity(entity),
                    };
                },
            }),
            buildCollection({
                path: "cms-teams",
                schema: teamSchema,
                name: "Teams",
                description: "Create and manage teams within the app.",
                initialFilter: teamFilter as FilterValues<Team>,
                permissions: ({ entity }) => {
                    return {
                        edit: canManageTeamEntity(entity),
                        create: isAdmin,
                        delete: false,
                    };
                },
                properties: ["teamName", "image", "primaryMarket"],
            }),
            buildCollection({
                path: "cms-team-arenas",
                schema: teamArenaSchema,
                callbacks: arenaCallbacks,
                name: "Team Arenas",
                description: "Create and manage team arenas within the app.",
                permissions: ({ entity }) => {
                    return {
                        edit: canManageTeamEntity(entity),
                        create: true,
                        delete: canManageTeamEntity(entity),
                    };
                },
            }),
            buildCollection({
                path: "cms-team-tickets",
                schema: teamTicketSchema,
                name: "Team Ticketing & Passes",
                description: "Create and manage team tickets within the app.",
                permissions: ({ entity }) => {
                    return {
                        edit: canManageTeamEntity(entity),
                        create: true,
                        delete: canManageTeamEntity(entity),
                    };
                },
            }),

            buildCollection({
                path: "cms-team-contacts",
                schema: teamContactSchema,
                name: "Team Contacts",
                description: "Create and manage team contacts within the app.",
                permissions: ({ entity }) => {
                    return {
                        edit: canManageTeamEntity(entity),
                        create: true,
                        delete: canManageTeamEntity(entity),
                    };
                },
            }),
            buildCollection({
                path: "cms-markets",
                schema: marketSchema,
                name: "Markets (admin)",
                description:
                    "Create and manage markets available to League Life users.",
                permissions: ({ authController }) => ({
                    edit: isAdmin,
                    create: isAdmin,
                    delete: false,
                }),
            }),
            buildCollection({
                path: "users",
                schema: userProfileSchema,
                name: "User Profiles (admin)",
                description: "",
                exportable: false,
                permissions: ({ authController }) => ({
                    edit: isAdmin,
                    create: false,
                    delete: false,
                }),
            }),
            buildCollection({
                path: "cms-team-invites",
                schema: teamInvites,
                name: "Team Invites",
                description: "",
                permissions: ({ entity }) => {
                    return {
                        edit: canManageTeamEntity(entity),
                        create: true,
                        delete: canManageTeamEntity(entity),
                    };
                },
            }),
        ];

        if (isAdmin) {
            const permissions = {
                edit: isAdmin,
                create: isAdmin,
                delete: isAdmin,
            };

            const adminCollections = [
                buildCollection({
                    path: "cms-services",
                    schema: serviceSchema,
                    name: "All Services (admin)",
                    description: "Create and manage services for all markets.",
                    permissions,
                    callbacks: serviceCallbacks,
                    subcollections: [checkinSchema],
                }),
                buildCollection({
                    path: "cms-promo-services",
                    schema: promoServiceSchema,
                    name: "Promo Services (admin)",
                    description:
                        "Create and manage promo services for each market.",
                    permissions,
                }),
                buildCollection({
                    path: "cms-concierge-feed-items",
                    schema: conciergeFeedItemSchema,
                    name: "Concierge Feed Items (admin)",
                    description:
                        "Create and manage home feed items for each market.",
                    permissions,
                }),
                buildCollection({
                    path: "cms-recommendations",
                    schema: recommendationSchema,
                    name: "Recommendations (admin)",
                    description:
                        "Create and manage recommendations for each market.",
                    permissions,
                    callbacks: recommendationCallbacks,
                }),
            ];

            adminCollections.forEach((adminCollection) =>
                collections.push(adminCollection)
            );
        }

        return {
            views: customViews,
            collections,
        };
    };

    const myAuthenticator: Authenticator<FirebaseUser> = async ({
        user,
        authController,
        dataSource,
    }) => {
        const teamFilter = {
            teamManagers: [
                "array-contains" as WhereFilterOp,
                new EntityReference(user?.uid ?? "", "/users/"),
            ],
        } as FilterValues<Team>;

        const teams = await dataSource.fetchCollection({
            path: "cms-teams",
            schema: teamSchema,
            filter: teamFilter,
        });

        const managedTeams: Entity<Team>[] = [];

        teams.forEach((team) =>
            team.values.teamManagers.forEach((manager) => {
                if (manager.id == user?.uid) managedTeams.push(team);
            })
        );

        const userProfile = await dataSource.fetchEntity({
            path: "users",
            entityId: user?.uid || "",
            schema: userProfileSchema,
        });

        const userData = {
            roles: [userProfile.values.isAdmin ? "admin" : "user"],
            managedTeams,
            myTeam: userProfile.values?.myTeam,
        };
        authController.setExtra(userData);

        if (userProfile.values.isAdmin || managedTeams.length) {
            // if the user should be able to access the admin dash
            return true;
        } else {
            return false;
        }
    };

    return (
        <FirebaseCMSApp
            name={
                process.env.REACT_APP_ENV === "production"
                    ? "League Life CMS"
                    : "League Life CMS (DEV)"
            }
            authentication={myAuthenticator}
            navigation={navigation}
            firebaseConfig={firebaseConfig}
        />
    );
}
