import { buildEntityCallbacks } from "@camberi/firecms";
import { Arena, Market, Recommendation, Service, Event } from "./types.d";
import serviceSchema from "./schemas/service";

export const formatGoogleMapsUrl = (
    url: string | null | undefined
): { lat: number; lon: number } | null => {
    const regex =
        /(?:google\.com).*@(-?[0-9]+\.[0-9]+),(-?[0-9]+\.[0-9]+),[0-9]+z/gm;

    const matches = regex.exec(url || "");

    if (matches?.length == 3) {
        return {
            lat: parseFloat(matches[1]),
            lon: parseFloat(matches[2]),
        };
    } else return null;
};

const checkGoogleMapsUrl = (values: any) => {
    let locationFromUrl = null;

    if (values.address?.geoLocation?.googleMapsUrl) {
        locationFromUrl = formatGoogleMapsUrl(
            values.address.geoLocation.googleMapsUrl
        );
    } else if (values.location?.googleMapsUrl) {
        locationFromUrl = formatGoogleMapsUrl(values.location.googleMapsUrl);
    } else {
        return;
    }

    if (locationFromUrl) {
        values.address.geoLocation.lat = locationFromUrl.lat;
        values.address.geoLocation.lon = locationFromUrl.lon;
    } else {
        throw Error(
            "Couldn't extract location from Google Maps URL. Please check or remove it."
        );
    }
};

export const recommendationCallbacks = buildEntityCallbacks<Recommendation>({
    onPreSave: async ({ schema, path, entityId, values, status, context }) => {
        values.serviceId = values.service.id;
        const serviceEntity = await context.dataSource.fetchEntity<Service>({
            path: "cms-services",
            entityId: values.service.id,
            schema: serviceSchema,
        });
        // eslint-disable-next-line max-len
        // const serviceEntity = Promise.resolve<Service>(context.dataSource.fetchEntity<Service>({path: "cms-services", entityId: svc_id, schema: serviceSchema}));
        values.serviceName = serviceEntity.values.name;
        values.imageUrl = serviceEntity.values.imageUrl;
        values.address = serviceEntity.values.address;

        checkGoogleMapsUrl(values);

        return values;
    },
});

export const serviceCallbacks = buildEntityCallbacks<Service>({
    onPreSave: ({ values, status }) => {
        // set a default value so it definitely saves - required by app
        if (values.favoriteCount == null) {
            values.favoriteCount = 0;
        }

        // Health Services should be sensitive by default
        if (status === "new" && values.category === "Health Services") {
            values.isSensitive = true;
        }

        checkGoogleMapsUrl(values);

        return values;
    },
});

export const arenaCallbacks = buildEntityCallbacks<Arena>({
    onPreSave: ({ values, status }) => {
        checkGoogleMapsUrl(values);
        return values;
    },
});

export const eventCallbacks = buildEntityCallbacks<Event>({
    onPreSave: ({ values, status }) => {
        checkGoogleMapsUrl(values);
        return values;
    },
});

export const marketCallbacks = buildEntityCallbacks<Event>({
    onPreSave: ({ values, status }) => {
        checkGoogleMapsUrl(values);
        return values;
    },
});
