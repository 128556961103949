import { buildSchema } from "@camberi/firecms";
import { Contact } from "../types.d";

export default buildSchema<Contact>({
    name: "Contacts",
    properties: {
        name: {
            title: "Name",
            dataType: "string",
            validation: { required: true, max: 250 },
        },
        role: {
            title: "Title",
            dataType: "string",
            validation: { required: true, max: 250 },
        },
        department: {
            title: "Department",
            dataType: "string",
            validation: { required: true, max: 250 },
        },
        officePhone: {
            title: "Office Phone",
            dataType: "string",
            validation: { required: false, max: 250 },
        },
        mobilePhone: {
            title: "Mobile Phone",
            dataType: "string",
            validation: { required: false, max: 250 },
        },
        email: {
            title: "Email",
            dataType: "string",
            validation: { required: false, max: 250 },
        },
        teamIds: {
            title: "Teams",
            dataType: "array",
            of: {
                dataType: "reference",
                path: "cms-teams",
            },
        },
    },
});
