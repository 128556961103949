import { buildSchema } from "@camberi/firecms";
import { Recommendation } from "../types.d";

export default buildSchema<Recommendation>({
    name: "Recommendation",
    properties: {
        market: {
            title: "Market",
            validation: { required: true },
            dataType: "reference",
            path: "cms-markets",
        },
        service: {
            title: "Service",
            description: "Recommended Service",
            dataType: "reference",
            path: "cms-services",
            validation: { required: true },
            previewProperties: ["name", "imageUrl"],
        },
        serviceId: {
            title: "Service ID",
            dataType: "string",
            validation: { required: false },
            disabled: {
                hidden: true,
            },
        },
        serviceName: {
            title: "Service Name",
            dataType: "string",
            validation: { required: false },
            disabled: {
                hidden: true,
            },
        },
        imageUrl: {
            title: "Image",
            validation: { required: false },
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "public",
                    acceptedFiles: ["image/*"],
                    storeUrl: true,
                },
            },
            dataType: "string",
            disabled: {
                hidden: true,
            },
        },
        address: {
            title: "Address",
            validation: { required: true },
            dataType: "map",
            properties: {
                streetAddress: {
                    title: "Street Address",
                    dataType: "string",
                },
                locality: {
                    title: "City",
                    dataType: "string",
                },
                region: {
                    title: "State",
                    dataType: "string",
                },
                zipCode: {
                    title: "Zip Code",
                    dataType: "string",
                },
                geoLocation: {
                    title: "GPS Coordinates",
                    dataType: "map",
                    properties: {
                        lat: {
                            title: "Latitude",
                            dataType: "number",
                        },
                        lon: {
                            title: "Longitude",
                            dataType: "number",
                        },
                    },
                },
            },
            disabled: {
                hidden: true,
            },
        },
        recommenderName: {
            title: "Recommender",
            description: "Recommender's name",
            dataType: "string",
            validation: { required: true },
        },
        createdAt: {
            title: "Created",
            dataType: "timestamp",
            autoValue: "on_create",
        },
    },
});
