import { buildSchema } from "@camberi/firecms";
import { Team } from "../types.d";

export default buildSchema<Team>({
    name: "Team",
    properties: {
        teamName: {
            title: "Team Name",
            validation: { required: true, max: 250 },
            dataType: "string",
        },
        image: {
            title: "Team Image",
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "public",
                    acceptedFiles: ["image/*"],
                    storeUrl: true,
                },
            },
        },
        primaryMarket: {
            title: "Primary Market",
            dataType: "reference",
            path: "cms-markets",
        },
        teamManagers: {
            title: "Team Managers",
            dataType: "array",
            of: {
                dataType: "reference",
                path: "users",
            },
        },
        teamMembers: {
            title: "Team Members",
            dataType: "array",
            of: {
                dataType: "reference",
                path: "users",
            },
        },
        arenas: {
            title: "Arenas",
            dataType: "array",
            of: {
                dataType: "reference",
                path: "cms-team-arenas",
            },
        },
        events: {
            title: "Events",
            dataType: "array",
            of: {
                dataType: "reference",
                path: "cms-team-events",
            },
        },
        ticketSummary: {
            title: "Ticket Summary",
            dataType: "array",
            oneOf: {
                typeField: "type",
                valueField: "value",
                properties: {
                    header: {
                        title: "Header",
                        dataType: "string",
                        validation: { required: true, max: 100 },
                    },
                    body: {
                        title: "Body",
                        dataType: "string",
                        validation: { required: true },
                    },
                },
            },
        },
        tickets: {
            title: "Ticketing & Passes",
            dataType: "array",
            of: {
                dataType: "reference",
                path: "cms-team-tickets",
            },
        },
        contacts: {
            title: "Contacts",
            dataType: "array",
            of: {
                dataType: "reference",
                path: "cms-team-contacts",
            },
        },
    },
});
