import { buildSchema } from "@camberi/firecms";
import { Arena } from "../types.d";

export default buildSchema<Arena>({
    name: "Arenas",
    properties: {
        name: {
            title: "Arena Name",
            dataType: "string",
            validation: { required: true, max: 250 },
        },
        teamIds: {
            title: "Teams",
            dataType: "array",
            of: {
                dataType: "reference",
                path: "cms-teams",
            },
        },
        address: {
            title: "Address",
            dataType: "map",
            properties: {
                streetAddress: {
                    title: "Street Address",
                    dataType: "string",
                },
                locality: {
                    title: "City",
                    dataType: "string",
                },
                region: {
                    title: "State",
                    dataType: "string",
                },
                zipCode: {
                    title: "Zip Code",
                    dataType: "string",
                },
                geoLocation: {
                    title: "GPS Coordinates",
                    dataType: "map",
                    properties: {
                        googleMapsUrl: {
                            title: "Google Maps Url",
                            dataType: "string",
                        },
                        lat: {
                            title: "Latitude",
                            dataType: "number",
                        },
                        lon: {
                            title: "Longitude",
                            dataType: "number",
                        },
                    },
                },
            },
        },
        seatingInfo: {
            title: "Seating Info",
            dataType: "array",
            of: {
                dataType: "map",
                properties: {
                    title: {
                        title: "Title",
                        validation: { required: true, max: 250 },
                        dataType: "string",
                    },

                    image: {
                        title: "Image",
                        validation: { required: false },
                        dataType: "string",
                        config: {
                            storageMeta: {
                                mediaType: "image",
                                storagePath: "public",
                                acceptedFiles: ["image/*"],
                                storeUrl: true,
                            },
                        },
                    },

                    detailsImage: {
                        title: "Details Image",
                        validation: { required: false },
                        dataType: "string",
                        config: {
                            storageMeta: {
                                mediaType: "image",
                                storagePath: "public",
                                acceptedFiles: ["image/*"],
                                storeUrl: true,
                            },
                        },
                    },

                    descriptions: {
                        title: "Descriptions",
                        dataType: "array",
                        of: {
                            dataType: "map",
                            properties: {
                                title: {
                                    title: "Title",
                                    validation: {
                                        required: true,
                                        max: 250,
                                    },
                                    dataType: "string",
                                },

                                body: {
                                    title: "Body",
                                    validation: {
                                        required: true,
                                        max: 250,
                                    },
                                    dataType: "string",
                                },
                            },
                        },
                    },
                },
            },
        },
        parkingInfo: {
            title: "Parking Info",
            dataType: "map",
            properties: {
                images: {
                    title: "Images",
                    dataType: "array",
                    of: {
                        title: "Image",
                        validation: { required: true },
                        dataType: "string",
                        config: {
                            storageMeta: {
                                mediaType: "image",
                                storagePath: "public",
                                acceptedFiles: ["image/*"],
                                storeUrl: true,
                            },
                        },
                    },
                },
                descriptions: {
                    title: "Descriptions",
                    dataType: "array",
                    of: {
                        dataType: "string",
                        validation: { required: true, max: 500 },
                    },
                },
                notes: {
                    title: "Notes",
                    dataType: "array",
                    of: {
                        dataType: "string",
                        validation: { required: true, max: 500 },
                    },
                },
            },
        },
    },
});
