import { buildSchema } from "@camberi/firecms";
import { TeamInvite } from "../types.d";
import { roles } from "./userProfileSchema";

export const roleEnumValues = new Map(
    roles.map((c) => [c, c])
); // i.e. use same key and value

export default buildSchema<TeamInvite>({
    name: "Team Invite (Set ID to email address)",
    description:
        "Create a new team invite. Please specify the email address as the document ID.",
    customId: true,
    properties: {
        team: {
            title: "Team",
            dataType: "reference",
            path: "cms-teams",
        },
        role: {
            title: "Chat Role",
            validation: { required: true },
            dataType: "string",
            config: {
                enumValues: roleEnumValues,
            },
        },
    },
});
