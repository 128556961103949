import { buildSchema } from "@camberi/firecms";
import { PromoService } from "../types.d";
import ColorPickerComponent from "../ColorPickerComponent";
import ColorPickerPreview from "../ColorPickerPreview";

export default buildSchema<PromoService>({
    name: "Promo Service",
    defaultValues: {
        favouriteCount: 0,
        active: true,
    },
    properties: {
        marketIds: {
            title: "Markets",
            validation: { required: true },
            dataType: "array",
            of: {
                title: "Market",
                dataType: "reference",
                path: "cms-markets",
            },
        },
        name: {
            title: "Name",
            validation: { required: true, max: 250 },
            dataType: "string",
        },
        active: {
            title: "Active",
            dataType: "boolean",
        },
        title: {
            title: "Title",
            validation: { required: true, max: 250 },
            dataType: "string",
        },
        ctaButtonText: {
            title: "CTA Button Text",
            validation: { required: true, max: 250 },
            dataType: "string",
        },
        tileBackgroundColor: {
            title: "Tile Background Color",
            validation: { required: true },
            dataType: "string",
            config: {
                Field: ColorPickerComponent,
                Preview: ColorPickerPreview,
                customProps: {
                    color: "#ffffff",
                },
            },
        },
        tileForegroundColor: {
            title: "Tile Foreground Color",
            validation: { required: true },
            dataType: "string",
            config: {
                Field: ColorPickerComponent,
                Preview: ColorPickerPreview,
                customProps: {
                    color: "#ffffff",
                },
            },
        },
        tileImageUrl: {
            title: "Tile Image",
            validation: { required: false },
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "public",
                    acceptedFiles: ["image/*"],
                    storeUrl: true,
                },
            },
            dataType: "string",
        },
        imageUrl: {
            title: "Image",
            validation: { required: true },
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "public",
                    acceptedFiles: ["image/*"],
                    storeUrl: true,
                },
            },
            dataType: "string",
        },
        logoImageUrl: {
            title: "Logo Image",
            validation: { required: false },
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "public",
                    acceptedFiles: ["image/*"],
                    storeUrl: true,
                },
            },
            dataType: "string",
        },
        videoUrl: {
            title: "Video",
            config: {
                storageMeta: {
                    mediaType: "video",
                    storagePath: "public",
                    acceptedFiles: ["video/*"],
                    storeUrl: true,
                },
            },
            dataType: "string",
        },
        aboutText: {
            title: "About Text",
            validation: { required: false, max: 250 },
            config: {
                multiline: true,
            },
            dataType: "string",
        },
    },
});
