import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, CircularProgress } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import { LoadingButton } from "@mui/lab";
// import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

import {
    buildSchema,
    useAuthController,
    useSideEntityController,
    useSnackbarController,
    useDataSource,
    Entity,
} from "@camberi/firecms";

import commentSchema from "../schemas/comment";
// eslint-disable-next-line import/no-unresolved
import { Service, Comment } from "../types";
import serviceSchema from "../schemas/service";

type ExtendedComment = {
    comment: Entity<Comment>;
    loading?: boolean;
    service: Entity<Service>;
};

/**
 * Sample CMS view not bound to a collection, customizable by the developer
 * @constructor
 */
export function CommentModerationView() {
    const [services, setServices] = useState<Entity<Service>[]>([]);
    const [comments, setComments] = useState<ExtendedComment[]>([]);
    const [commentUpdated, setCommentUpdated] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);

    // const columns: GridColDef[] = [
    //     { field: "name", headerName: "Name" },
    //     { field: "action", headerName: "Action" },
    //     { field: "state", headerName: "State" },
    //     {
    //         field: "text",
    //         headerName: "Comment",
    //     },
    //     {
    //         field: "imageUrl",
    //         headerName: "Image",
    //         sortable: false,
    //     },
    // ];

    // hook to display custom snackbars
    const snackbarController = useSnackbarController();

    // hook to open the side dialog that shows the entity forms
    const sideEntityController = useSideEntityController();

    // hook to do operations related to authentication
    const authController = useAuthController();

    // firestore data source
    const dataSource = useDataSource();

    useEffect(() => {
        const newComments: ExtendedComment[] = [];
        setPageLoading(true);

        dataSource
            .fetchCollection({
                path: "cms-services",
                schema: serviceSchema,
            })
            .then((data) => {
                console.log("Fetched services!");
                Promise.all(
                    data.map((service) => {
                        return dataSource
                            .fetchCollection({
                                path:
                                    "cms-services/" + service.id + "/comments",
                                schema: commentSchema,
                            })
                            .then((data) => {
                                data.forEach((comment) => {
                                    newComments.push({
                                        comment: comment,
                                        service: service,
                                    });
                                });
                            });
                    })
                )
                    .then(() => {
                        setServices(data);
                        console.log(`Fetched ${data.length} services!`);

                        setComments(
                            newComments.sort((a, b) => {
                                return b.comment.values.state >
                                    a.comment.values.state
                                    ? 1
                                    : -1;
                            })
                        );
                        console.log(`Fetched ${newComments.length} comments!`);
                    })
                    .finally(() => {
                        setPageLoading(false);
                    });
            });
    }, [setComments, commentUpdated]);

    return (
        <React.Fragment>
            {/* I have to do this in React */}
            {pageLoading && (
                <Box
                    sx={{ mt: 2 }}
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <CircularProgress />
                </Box>
            )}

            {!pageLoading && (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Action</TableCell>
                                <TableCell>State</TableCell>
                                <TableCell>Service Name</TableCell>
                                <TableCell>Comment</TableCell>
                                <TableCell>User</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {comments.map((comment) => (
                                <React.Fragment key={comment.comment.id}>
                                    <TableRow
                                        key={comment.comment.id}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <LoadingButton
                                                sx={{ mr: 1 }}
                                                variant="contained"
                                                disabled={
                                                    comment.comment.values
                                                        .state === "approved"
                                                }
                                                loading={
                                                    comment?.loading === true
                                                }
                                                color="primary"
                                                onClick={() => {
                                                    comment.loading = true;
                                                    dataSource
                                                        .saveEntity({
                                                            path:
                                                                "cms-services/" +
                                                                comment.service
                                                                    .id +
                                                                "/comments/",
                                                            entityId:
                                                                comment.comment
                                                                    .id,
                                                            schema: commentSchema,
                                                            status: "existing",
                                                            values: {
                                                                state: "approved",
                                                            },
                                                        })
                                                        .then(() => {
                                                            snackbarController.open(
                                                                {
                                                                    message:
                                                                        "Comment approved!",
                                                                    type: "success",
                                                                }
                                                            );
                                                            setCommentUpdated(
                                                                !commentUpdated
                                                            );
                                                        });
                                                }}
                                            >
                                                Approve
                                            </LoadingButton>

                                            <LoadingButton
                                                variant="contained"
                                                disabled={
                                                    comment.comment.values
                                                        .state === "rejected"
                                                }
                                                loading={
                                                    comment?.loading === true
                                                }
                                                color="primary"
                                                onClick={() => {
                                                    comment.loading = true;
                                                    dataSource
                                                        .saveEntity({
                                                            path:
                                                                "cms-services/" +
                                                                comment.service
                                                                    .id +
                                                                "/comments/",
                                                            entityId:
                                                                comment.comment
                                                                    .id,
                                                            schema: commentSchema,
                                                            status: "existing",
                                                            values: {
                                                                state: "rejected",
                                                            },
                                                        })
                                                        .then(() => {
                                                            snackbarController.open(
                                                                {
                                                                    message:
                                                                        "Comment rejected!",
                                                                    type: "success",
                                                                }
                                                            );
                                                            setCommentUpdated(
                                                                !commentUpdated
                                                            );
                                                        });
                                                }}
                                            >
                                                Reject
                                            </LoadingButton>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Chip
                                                label={
                                                    comment.comment.values.state
                                                }
                                                color={
                                                    comment.comment.values
                                                        .state === "approved"
                                                        ? "success"
                                                        : comment.comment.values
                                                              .state ===
                                                          "rejected"
                                                        ? "error"
                                                        : "warning"
                                                }
                                            />
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {comment.service.values.name}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {comment.comment.values.text}
                                        </TableCell>

                                        <TableCell align="right">
                                            <p>{comment.comment.values.name}</p>
                                            <img
                                                style={{ maxWidth: "50px" }}
                                                src={
                                                    comment.comment.values
                                                        .imageUrl
                                                }
                                                alt="No Image"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </React.Fragment>
    );
}
