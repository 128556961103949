import { buildSchema } from "@camberi/firecms";
import { UserProfile } from "../types.d";
import { serviceCategoryEnumValues } from "../schemas/service";

export const roles = [
    "Admin",
    "Executive",
    "Player",
    "Coach",
    "Staff",
    "Friends and Family",
];

export const roleEnumValues = new Map(
    roles.map((c) => [c, c])
); // i.e. use same key and value


export default buildSchema<UserProfile>({
    name: "User Profile",
    properties: {
        firstName: {
            title: "First Name",
            dataType: "string",
            validation: { required: true, max: 250 },
        },
        lastName: {
            title: "Last Name",
            dataType: "string",
            validation: { required: true, max: 250 },
        },
        email: {
            title: "email",
            dataType: "string",
            readOnly: true,
        },
        isAdmin: {
            title: "Is Admin",
            dataType: "boolean",
        },
        role: {
            title: "Chat Role",
            validation: { required: true },
            dataType: "string",
            config: {
                enumValues: roleEnumValues,
            },
        },
        myTeam: {
            title: "User's Team",
            dataType: "reference",
            path: "cms-teams",
            readOnly: true,
        },
        managerOfTeams: {
            title: "User's Managed Teams",
            dataType: "reference",
            path: "cms-teams",
            readOnly: true,
        },
        selectedMarket: {
            title: "User's Market",
            dataType: "reference",
            path: "cms-markets",
        },
        preferredServiceCategories: {
            title: "Preferred Service Categories",
            dataType: "array",
            of: {
                dataType: "string",
                title: "Preferred Service Category",
                config: {
                    enumValues: serviceCategoryEnumValues,
                },
            },
        },
    },
});
