import { buildSchema } from "@camberi/firecms";
import { Comment } from "../types.d";

export default buildSchema<Comment>({
    name: "Comment",
    properties: {
        id: {
            title: "Title",
            validation: { required: true, max: 250 },
            dataType: "string",
        },
        createdAt: {
            title: "Created",
            dataType: "timestamp",
        },
        userId: {
            title: "User ID",
            validation: { required: true },
            dataType: "string",
        },
        imageUrl: {
            title: "Image",
            validation: { required: false },
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "public",
                    acceptedFiles: ["image/*"],
                    storeUrl: true,
                },
            },
            dataType: "string",
        },
        name: {
            title: "Name",
            validation: { required: true, max: 250 },
            dataType: "string",
        },
        text: {
            title: "Text",
            validation: { required: true, max: 250 },
            dataType: "string",
        },
        state: {
            title: "Text",
            validation: { required: true },
            dataType: "string",
        },
        isPrivate: {
            title: "Text",
            validation: { required: false },
            dataType: "boolean",
        },
    },
});
