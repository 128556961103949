import { buildCollection, buildSchema } from "@camberi/firecms";
import { Service } from "../types.d";

export const serviceCategories = [
    "Transportation",
    "Dining",
    "Fitness",
    "Health Services",
    "Home Services",
    "Kids Corner",
    "Beauty & Wellness",
    "Real Estate",
    "Retail",
    "Attractions",
    "Pets",
    "Men's Style",
];

export const serviceCategoryEnumValues = new Map(
    serviceCategories.map((c) => [c, c])
); // i.e. use same key and value

export const serviceSchema = buildSchema<Service>({
    name: "Service",
    defaultValues: {
        favouriteCount: 0,
        isSensitive: false,
        isBookingEnabled: false,
        isFeatured: false,
    },
    properties: {
        market: {
            title: "Market",
            validation: { required: true },
            dataType: "reference",
            path: "cms-markets",
        },
        category: {
            title: "Category",
            validation: { required: true },
            dataType: "string",
            config: {
                enumValues: serviceCategoryEnumValues,
            },
        },
        subcategory: {
            title: "Subcategory",
            validation: { required: false, max: 250 },
            dataType: "string",
        },
        name: {
            title: "Name",
            validation: { required: true, max: 250 },
            dataType: "string",
        },
        checkinCount: {
            title: "Checkin Count",
            dataType: "number",
            readOnly: true,
        },
        imageUrl: {
            title: "Image",
            validation: { required: false },
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "public",
                    acceptedFiles: ["image/*"],
                    storeUrl: true,
                },
            },
            dataType: "string",
        },
        description: {
            title: "Description",
            validation: { required: false, max: 250 },
            config: {
                multiline: true,
            },
            dataType: "string",
        },
        websiteUrl: {
            title: "Website",
            validation: { required: false },
            dataType: "string",
        },
        emailAddress: {
            title: "Email",
            validation: { required: false },
            dataType: "string",
        },
        phoneNumber: {
            title: "Phone",
            validation: { required: false },
            dataType: "string",
        },
        address: {
            title: "Address",
            validation: { required: false },
            dataType: "map",
            properties: {
                streetAddress: {
                    title: "Street Address",
                    dataType: "string",
                },
                locality: {
                    title: "City",
                    dataType: "string",
                },
                region: {
                    title: "State",
                    dataType: "string",
                },
                zipCode: {
                    title: "Zip Code",
                    dataType: "string",
                },
                geoLocation: {
                    title: "GPS Coordinates",
                    dataType: "map",
                    properties: {
                        googleMapsUrl: {
                            title: "Google Maps Url",
                            dataType: "string",
                        },
                        lat: {
                            title: "Latitude",
                            dataType: "number",
                        },
                        lon: {
                            title: "Longitude",
                            dataType: "number",
                        },
                    },
                },
            },
        },
        openingHours: {
            title: "Opening Hours",
            validation: { required: false },
            dataType: "array",
            of: {
                dataType: "map",
                properties: {
                    title: {
                        title: "Day(s) of Week",
                        dataType: "string",
                    },
                    descriptions: {
                        title: "Hours / Description",
                        dataType: "array",
                        of: {
                            dataType: "string",
                            title: "Hours / Description Item",
                            validation: { required: true, min: 3 },
                        },
                        validation: { required: true, min: 1 },
                    },
                },
            },
        },
        parkingNotes: {
            title: "Additional Notes",
            validation: { required: false, max: 250 },
            dataType: "string",
        },
        commonQuestions: {
            title: "Common Questions",
            validation: { required: false },
            dataType: "array",
            of: {
                dataType: "map",
                properties: {
                    question: {
                        title: "Question",
                        dataType: "string",
                        validation: { required: true, min: 1, max: 250 },
                    },
                    answer: {
                        title: "Answer",
                        dataType: "string",
                        validation: { required: true, min: 1, max: 250 },
                        config: {
                            multiline: true,
                        },
                    },
                },
            },
        },
        favoriteCount: {
            // this is set to 0 in a pre-save hook if not present
            title: "Favorites",
            validation: { required: false },
            disabled: {
                hidden: true,
            },
            dataType: "number",
        },
        isBookingEnabled: {
            title: "Booking Enabled",
            validation: { required: false },
            dataType: "boolean",
        },
        bookingUrl: ({ values }) => ({
            title: "Booking URL",
            validation: { required: false },
            dataType: "string",
            // disabled: !values.isBookingEnabled,
        }),
        isSensitive: {
            title: "Sensitive Service",
            description:
                "When set this service will not display favorited status of other users",
            validation: { required: false },
            dataType: "boolean",
        },
        isFeatured: {
            title: "Featured Service",
            description: "Show this service in the Featured category",
            validation: { required: false },
            dataType: "boolean",
        },
    },
});

export const checkinSchema = buildCollection({
    name: "Checkins",
    path: "checkins",
    schema: {
        name: "Checkins",
        properties: {
            checkedInAt: {
                title: "Checked In At",
                validation: { required: true },
                dataType: "timestamp",
                readOnly: true,
            },
            user: {
                title: "User",
                validation: { required: true },
                dataType: "reference",
                path: "users",
                readOnly: true,
            },
            service: {
                title: "Service",
                validation: { required: true },
                dataType: "reference",
                path: "cms-services",
                readOnly: true,
            },
        },
    },
});

export default serviceSchema;
