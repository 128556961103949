import React, { useState, useEffect, useCallback } from "react";
import type * as CSS from "csstype";

import {
    IconButton,
    Button,
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { Add, KeyboardTab } from "@mui/icons-material";

import {
    useSideEntityController,
    useSnackbarController,
    Entity,
    CollectionTable,
    useNavigation,
    EntityReference,
    buildCollection,
    CollectionSize,
    useDataSource,
    WhereFilterOp,
} from "@camberi/firecms";

// eslint-disable-next-line import/no-unresolved
import { Market, Service } from "../types";
import serviceSchema from "../schemas/service";
import marketSchema from "../schemas/markets";
import { serviceCallbacks } from "../callbacks";

export function ServiceView() {
    const [markets, setMarkets] = React.useState([
        {
            name: "Markets Loading...",
            id: "ALL",
            location: { lat: -27.1, lon: 120.1 },
        },
    ] as Market[]);
    const [market, setMarket] = React.useState(markets[0]);

    const getCollection = (marketId: string) => {
        const initialFilter = {
            market: [
                "==" as WhereFilterOp,
                new EntityReference(marketId ?? "", "/cms-markets/"),
            ],
        };

        return buildCollection({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            initialFilter: marketId === "ALL" ? undefined : initialFilter,
            path: "cms-services",
            schema: serviceSchema,
            name: "Services",
            description: "Create and manage services for each market.",
            permissions: ({ authController }) => ({
                edit: true,
                create: true,
                delete: authController.extra.roles.includes("admin"),
            }),
            callbacks: serviceCallbacks,
        });
    };

    const [collection, setCollection] = React.useState(
        getCollection(market?.id || "ALL")
    );

    const handleMarketChange = (event: SelectChangeEvent) => {
        const newMarketSelected = markets.filter(
            (val) => val.id === event.target.value
        )[0];

        setMarket(newMarketSelected);
        setCollection(getCollection(newMarketSelected?.id || "ALL"));
    };

    // hook to open the side dialog that shows the entity forms
    const sideEntityController = useSideEntityController();

    // firecms navigation context
    const navContext = useNavigation();

    const path = "/cms-services";

    const collectionResolver = navContext.getCollectionResolver("cms-services");

    if (!collectionResolver) throw Error("Collection resolver is undefined!!!");
    const { schemaResolver } = collectionResolver;

    const dataSource = useDataSource();

    useEffect(() => {
        dataSource
            .fetchCollection({
                path: "cms-markets/",
                schema: marketSchema,
            })
            .then(async (marketList) => {
                setMarkets([
                    {
                        name: "All Markets",
                        id: "ALL",
                        location: { lat: -27.1, lon: 120.1 },
                    },
                    ...marketList.map((market): Market => {
                        return {
                            name: market.values.name,
                            id: market.id,
                            location: market.values.location,
                        };
                    }),
                ]);
            });
    }, []);

    const onEntityClick = useCallback(
        (entity: Entity<Service>) => {
            return sideEntityController.open({
                entityId: entity.id,
                path,
                permissions: collection.permissions,
                schema: collection.schema,
                subcollections: collection.subcollections,
                callbacks: collection.callbacks,
                overrideSchemaRegistry: false,
            });
        },
        [path, collection, sideEntityController]
    );

    const onNewClick = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            return sideEntityController.open({
                path,
                permissions: collection.permissions,
                schema: collection.schema,
                subcollections: collection.subcollections,
                callbacks: collection.callbacks,
                overrideSchemaRegistry: false,
            });
        },
        [path, collection, sideEntityController]
    );

    const toolbarActionsBuilder = useCallback(() => {
        const addButton = (
            <Button
                onClick={onNewClick}
                startIcon={<Add />}
                size="large"
                variant="contained"
                color="primary"
            >
                Add {collection.schema.name}
            </Button>
        );

        return <>{addButton}</>;
    }, [path, collection]);

    const tableRowActionsBuilder = useCallback(
        ({
            entity,
            size,
        }: {
            entity: Entity<Service>;
            size: CollectionSize;
        }) => {
            return (
                <IconButton size="large">
                    <KeyboardTab />
                </IconButton>
            );
        },
        [sideEntityController, path]
    );

    const marketSelectorStyles: CSS.Properties = {
        maxWidth: "300px",
        margin: "20px auto",
        padding: "0 20px",
        height: "56px",
    };

    return (
        <React.Fragment>
            <div className="marketSelector" style={marketSelectorStyles}>
                <FormControl fullWidth>
                    <InputLabel id="market-select-label">Market</InputLabel>
                    <Select
                        labelId="market-select-label"
                        id="market-select"
                        value={market.id}
                        label="Market"
                        onChange={handleMarketChange}
                    >
                        {markets.map((market, i) => (
                            <MenuItem value={market.id} key={i}>
                                {market.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            <div
                style={{
                    width: "100%",
                    height: "100%",
                    overflow: "auto",
                    flexGrow: "1",
                    maxHeight: "calc(100% - 96px)",
                }}
            >
                <CollectionTable
                    path="cms-services"
                    collection={collection}
                    key={market.id}
                    inlineEditing={false}
                    schemaResolver={schemaResolver}
                    onEntityClick={onEntityClick}
                    toolbarActionsBuilder={toolbarActionsBuilder}
                    tableRowActionsBuilder={tableRowActionsBuilder}
                />
            </div>
        </React.Fragment>
    );
}
